import axios from '@/plugins/axios.js';
import store from '@/store/index.js';


export default {    
    
    async processQueuedRequests(queuedRequests) {        
        await queuedRequests.reduce(async (promise, request) => {
            await promise;
            switch (request.method) {
                case 'post':
                    return axios.post(request.url, request.body);
                case 'put':
                    return axios.put(request.url, request.body);
                case 'delete':
                    return axios.delete(request.url);
                default:
                    return;
            }
        }, true); 

        store.dispatch('OfflineModule/emptyQueue');
        return Promise.resolve();
    },    
}
