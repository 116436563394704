<template>
  <v-chip v-if="!connected" color="red" text-color="white" class="mx-1">
    <v-icon :left="queuedRequests.length > 0">mdi-wifi-off</v-icon>
    <span v-if="queuedRequests.length > 0">{{queuedRequests.length}}</span>    
  </v-chip>

  <v-chip v-else-if="connected && queuedRequests.length > 0" color="green" text-color="white" class="mx-1">
    <v-icon left>mdi-upload</v-icon>
    <v-progress-circular v-if="isLoading" :size="24" color="white" indeterminate></v-progress-circular>    
  </v-chip>
</template>


<script>
import {mapState} from 'vuex';
import offlineService from '@/services/offline.service.js';

export default {
  name: 'OfflineRequestCache',
  data: () => ({
    isLoading: false,
  }),

  computed: {
    ...mapState(["connected"]),
    ...mapState("OfflineModule", ['queuedRequests'])
  },
  methods: {
    async processQueuedRequests() {
      this.isLoading = true;

      try {
        await offlineService.processQueuedRequests(this.queuedRequests);
      } finally {
        this.isLoading = false;
      }
    }
  },  
  watch: {
    connected() {
      this.processQueuedRequests();
    }
  }
  
};
</script>

<style scoped>
    .deleted {
      text-decoration: line-through;
      color: red;
    }
</style>
