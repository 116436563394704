<template>
    <v-navigation-drawer app v-if="isAuthenticated" :value="null">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">{{currentUser.firstName}} {{currentUser.lastName}}</v-list-item-title>
              <v-list-item-subtitle>{{currentUser.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list nav>
          <v-list-item link :to="{name: 'Budget'}" v-if="hasRole('BudgetUser')">
            <v-list-item-icon>
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Budget</v-list-item-title>
          </v-list-item>

          <v-list-item link :to="{name: 'Select Vehicle'}" v-if="hasRole('FuelTrackingUser')">
            <v-list-item-icon>
              <v-icon>mdi-fuel</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Fuel Tracking</v-list-item-title>
          </v-list-item>          
        </v-list>

        <v-divider></v-divider>

        <v-list nav dense>
          <v-list-item link :to="{name: 'Admin'}" v-if="hasRole('Admin')">
            <v-list-item-icon>
              <v-icon>mdi-shield-key</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "SideNav",
  components: {},
  data: () => ({
    
  }),
  computed: {
    ...mapGetters("AuthModule", { 
      currentUser: "currentUser", 
      isAuthenticated: "isAuthenticated",
      hasRole: "hasRole"
    }),
  },
  methods: {
  },
  mounted() {
  },
  watch: {
  }
};
</script>

<style scoped>
</style>
