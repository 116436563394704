import Vue from 'vue'
import Vuex from 'vuex'
import {CoreModule} from '@/store/modules/core.module.js';
import {AuthModule} from '@/store/modules/auth.module.js';
import {BudgetModule} from '@/store/modules/budget.module.js';
import {OfflineModule} from '@/store/modules/offline.module.js';
import {MessageHubModule} from '@/store/modules/messageHub.module.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    connected: navigator.onLine,
  },
  getters: {
    connected: state => state.connected,
    
  },
  mutations: {
    SET_CONNECTED(state, payload) {
      state.connected = payload
    },
  },
  actions: {
    resetState({commit}) {
      commit("CoreModule/RESET_STATE");
      commit("AuthModule/RESET_STATE");
      commit("BudgetModule/RESET_STATE");
    },
    setConnected ({ commit }, payload) {
      commit('SET_CONNECTED', payload)
    },
  },
  modules: {
    AuthModule,
    BudgetModule,
    CoreModule,
    OfflineModule,
    MessageHubModule,
  }
})
