import Toastification from '@/components/Core/Toastification.vue';
import Vue from "vue";


export default {
    showToast(type, icon, title, message, actionButtonText, action, actionProps) {
        // Define the content object with the component, props and listeners
        const content = {
          component: Toastification,
          // Any prop can be passed, but don't expect them to be reactive
          props: {
            title: title,
            message: message,
            actionButtonText: actionButtonText,
            action: action
          },
          // Listen and react to events using callbacks. In this case we listen for
          // the "click" event emitted when clicking the toast button
          listeners: {
            click: () => action(actionProps)
          }
        }  
        
        Vue.$toast(content, {type: type, icon: `mdi ${icon}`,});
    }
}