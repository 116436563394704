import axios from 'axios';
import authService from '@/services/auth.service.js';
import store from '../store';
import toasterHandler from "@/utils/toaster.handler.js";


axios.defaults.baseURL = `${process.env.VUE_APP_WEBAPI_URL}/api`;

axios.interceptors.request.use(request => {
    request.headers['UserName'] = `${store.getters['AuthModule/currentUser'].firstName} ${store.getters['AuthModule/currentUser'].lastName}`;

    let isOffline = !store.getters["connected"];
    let isQueueable = ['post','put','delete'].indexOf(request.method.toLowerCase()) >= 0 && 
        request.url.toLowerCase().indexOf('login') < 0 && 
        request.url.toLowerCase().indexOf('/auth/refresh') < 0;
    
    // if offline... add POST/PUT/PATCH/DELETE requests on offline queue
    if(isOffline && isQueueable) {
        let queueItem = {            
            body: request.data,
            method: request.method,
            url: request.url,
        }

        store.dispatch('OfflineModule/queueRequest', queueItem);
        return Promise.reject("Offline");
    } 

    // add Bearer token to request headers
    const token = authService.getAccessToken();

    if (token) {
        request.headers['Authorization'] = `Bearer ${ token }`;
    }
    
    return request;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {    
    return response;
}, (error) => {
    console.log(error);
    if (error && error.response && error.response.status) {
        // let errorMessage = error.response.data.message 
        //     ? error.response.data.message
        //     : unspecifiedErrorMessage;

        switch (error.response.status) {            
            case 401:
                authService.signOut();
                break;            
            default:
                toasterHandler.showToast('error', 'mdi-alert-circle-outline', null, error.message, null, null, null);
                break;
        }
    } else {  
        toasterHandler.showToast('error', 'mdi-alert-circle-outline', null, error.message, null, null, null);
    }
});

export default axios;
