const queueName = 'requestQueue';

const getDefaultState = () => {
    return {
        queuedRequests: localStorage.getItem(queueName) ? [...JSON.parse(localStorage.getItem(queueName))] : [],
    }
}

export const OfflineModule = {
    namespaced: true,
    state: getDefaultState(),    
    getters: {
        queuedRequests: state => state.queuedRequests,
    },
    mutations: {                        
        UPDATE_STATE(state) {
            state.queuedRequests = localStorage.getItem(queueName) ? [...JSON.parse(localStorage.getItem(queueName))] : [];
        }
    },

    actions: { 
        queueRequest({ commit }, request) {
            console.log({offlineModule: request})
            let queuedRequests = localStorage.getItem(queueName) 
                ? [...JSON.parse(localStorage.getItem(queueName))] 
                : [];

            let newIndex = queuedRequests.length > 0 ? Math.max(queuedRequests.map(x => x.queueIndex)) + 1 : 0;
            queuedRequests.push({queueIndex: newIndex, ...request});            
            localStorage.setItem(queueName, JSON.stringify(queuedRequests));
            commit("UPDATE_STATE");
        },
        emptyQueue({commit}) {
            localStorage.setItem(queueName, JSON.stringify([]));
            commit("UPDATE_STATE");
        }
    }
}
