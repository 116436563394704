<template>
  <v-app-bar app color="primary" dark>
    <!-- <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon> -->
    <v-btn v-if="isSmallScreen" icon :to="{ name: 'Home' }">
      <v-icon>mdi-home</v-icon>
    </v-btn>
    <div class="d-flex align-center">
      <v-list-item :to="{ name: 'Home' }" dark>
        <v-img
          v-if="!isSmallScreen"
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />
        <h1 class="white--text">HomePortal</h1>
      </v-list-item>
    </div>

    <v-spacer></v-spacer>

    <!-- <v-tooltip v-if="isAuthenticated && !isConnected" bottom color="error">
      <template v-slot:activator="{ on, attrs }">
        <v-chip 
          class="ma-2" 
          color="error" 
          text-color="white"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi mdi-power-plug-off-outline</v-icon>
        </v-chip>
      </template>
      <span>Lost socket connection</span>
    </v-tooltip>           -->
    <WebSocketStatusIndicator />
    <OfflineRequestCache />
    <user-menu />
  </v-app-bar>
</template>

<script>
import UserMenu from "@/components/Core/UserMenu.vue";
import OfflineRequestCache from "@/components/Core/OfflineRequestCache.vue";
import WebSocketStatusIndicator from "@/components/Core/WebSocketStatusIndicator.vue";

export default {
  name: "NavBar",

  components: {
    UserMenu,
    OfflineRequestCache,
    WebSocketStatusIndicator
  },
  computed: {
    
    isSmallScreen() {
      const screenSizes = ["xs", "sm"];
      return screenSizes.indexOf(this.$vuetify.breakpoint.name) >= 0;
    },
  },

  data: () => ({
    drawer: false,
  }),

  methods: {    
    toggleMenu() {
      this.$emit("openMenu");
    },
  },
};
</script>
