import Vue from 'vue'
import VueRouter from 'vue-router';
import authService from '@/services/auth.service.js';
// import FuelTrackingGraphs from '@/views/FuelTracking/FuelTrackingGraphs';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Auth/Login.vue')
  },
  {
    path: '/budget',
    name: 'Budget',
    component: () => import('@/views/Budget.vue'),
    meta: {
      requiresAuth: true,
      roles: ["BudgetUser"],
    },    
  },
  {
    path: '/fuel-tracking',
    name: 'Fuel Tracking',
    component: () => import('@/views/FuelTracking/FuelTracking.vue'),    
    meta: {
      requiresAuth: true,
      roles: ["FuelTrackingUser"]
    },       
    children: [
      {
        path: 'fuel-entries/:vehicleId',
        name: 'Fuel Entries',
        component: () => import('@/views/FuelTracking/FuelEntries.vue'),
      },
      {
        path: 'vehicle-details/:id',
        name: 'Vehicle Detail',
        component: () => import('@/views/FuelTracking/VehicleDetail.vue'),
      },
      // { 
      //   path: 'graphs', 
      //   name: 'Fuel Entry Graphs', 
      //   component: FuelTrackingGraphs,
      // },   
      {
        path: '',
        name: 'Select Vehicle',
        component: () => import('@/views/FuelTracking/SelectVehicle.vue'),        
      },
    ],
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/Admin.vue'),
    meta: {
      requiresAuth: true,
      roles: ["Admin"]
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const currentUser = authService.getCurrentUserFromLocalStorage();
  const isAuthenticated = currentUser ? !!currentUser.email : false;
  

  // Prevent authenticated users from navigating to login page
  if (to.name === 'Login' && isAuthenticated) {
    console.log('Router: user is already logged in...')
    return next({path: from.path});
  }

  if (requiresAuth && !isAuthenticated) {
    console.log('Router: route requires authentication...')
    return next({name: 'Login'});
  }

  const routeRoles = to.meta.roles;  
  if (!routeRoles || routeRoles.length === 0) {
    console.log('Router: route has no required roles...');
    return next();
  }
  
  const userRoles = currentUser.roles;
  const hasRole = routeRoles.filter(reqRole => userRoles.find(ur => ur.name === reqRole));

  if (hasRole) {
    console.log(`Router: User is allowed`)
    return next();
  }  
  
  console.log('Router: user does not have required role...');
  return next({path: from.path});
})

export default router
