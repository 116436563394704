<template>
  <v-dialog :value="value" width="300px">
    <v-card>
      <v-card-text class="text-center pt-4">
        <h1 class="text-center mb-4">Update Available</h1>

        <div class="text-center">New content is available</div>        
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" @click="reload">
          <v-icon class="refresh-icon" left>mdi-refresh</v-icon> Update
        </v-btn>
      </v-card-actions>
    </v-card>    
  </v-dialog>
</template>

<script>
export default {
  name: "updateavailable",
  data() {
    return {}
  },
  props: [ "value", "title", "subTitle" ],
  methods: {
    reload() {
      this.$emit('update');
    }
    // reloadPage(){
    //   location.reload();
    // }    
  },
}

</script>

<style scoped>

</style>