<template>
    <div class="toast-container">
        <div class="toast-body">
            <div v-if="title" class="title">{{title}}</div>
            <div v-if="message" class="message">{{message}}</div>
        </div>
        <div v-if="action" class="actions">
            <button @click.stop="onClick">{{actionButtonText}}</button>
        </div>
    </div>
</template>
<script>
export default {
  props: ["title", "message", "actionButtonText", "action"],
  methods: {
    onClick() {
      // Emit a "click" event when clicked.
      // Can be any event though and even pass parameters back
      this.$emit("click");
    }
  }
};
</script>
<style>
.toast-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toast-container .toast-body {
    margin-right: 20px;
}
.toast-container .toast-body .title {
    font-size: 1.2em;
    text-decoration: underline;
    margin-bottom: 4px;
}
.toast-container .toast-body .message {
    white-space: pre;
    padding-left: 12px;
    font-size: .9em;
}
.toast-container .actions button {
    align-self: center;
    height: 40px;
    padding: 5px 10px;
    border-radius: 5px;    
    cursor: pointer;
    background-color: white;
    border: 1px solid #888;
    color: #666;
}
</style>
