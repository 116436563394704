import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker.js';
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Vuex from 'vuex';
import moment from "moment";
import { VueMaskDirective } from "v-mask";
import VueTheMask from 'vue-the-mask'
import Toast, { POSITION, TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.config.productionTip = false;
Vue.directive("mask", VueMaskDirective);
Vue.filter("monthDay", date => !date ? '' : moment(date).format('M/DD'));
Vue.filter("shortDate", date => !date ? '' : moment(date).format('M/DD/yyyy'));
Vue.filter("shortDateTime", date => !date ? '' : moment(date).format('M/DD/yyyy h:mm:ssa UTC'));
Vue.filter("monthName", date => !date ? '' : moment(date).format('MMMM'));
Vue.filter("dateNumber", date => !date ? '' : moment(date).format('D'));
Vue.filter("year", date => !date ? '' : moment(date).format('yyyy'));

Vue.filter('decimal', function (value, precision) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {            
      minimumFractionDigits: precision === undefined ? 2 : precision,
      maximumFractionDigits: precision === undefined ? 2 : precision
  });
  return formatter.format(value);
});

Vue.filter('currency', function (value, precision) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: precision === undefined ? 2 : precision,
      maximumFractionDigits: precision === undefined ? 2 : precision
  });
  return formatter.format(value);
});

const options = {
  position: POSITION.TOP_RIGHT,
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.ERROR]: {
        timeout: 8000,
        closeButton: true,
    },
    [TYPE.SUCCESS]: {
        timeout: 5000,
        // hideProgressBar: true,
    },
    [TYPE.DEFAULT]: {
      timeout: 5000
    },
    [TYPE.INFO]: {
      timeout: 5000
    },
    [TYPE.WARNING]: {
      timeout: 5000,
    }
  }
};

Vue.use(Vuex);
Vue.use(VueTheMask);
Vue.use(Toast, options);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')





