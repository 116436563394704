<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <v-main>
      <NavBar />
      <v-overlay :value="isLoading">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <template v-if="!isLoading">
        <SideNav /> 
        <router-view></router-view>
      </template>
    </v-main>

    <Snackbar/>

    <RefreshModal :value="isRefresh" @update="update()" />
  </v-app>
</template>

<script>
import NavBar from "@/components/Core/NavBar.vue";
import SideNav from "@/components/Core/SideNav.vue";
import Snackbar from "@/components/Core/Snackbar.vue";
import authService from '@/services/auth.service.js';
import RefreshModal from '@/components/Core/RefreshModal.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "App",

  components: {
    NavBar,
    SideNav,
    Snackbar,
    RefreshModal,
  },

  data: () => ({
    isLoading: false,
    drawer: false,

    // service worker update handling
    registration: null,
    isRefresh: false,
    refreshing: false,
  }),
  methods: {
    ...mapActions(['setConnected']),
    ...mapActions("MessageHubModule", ["initialize", "reconnect"]),
    appUpdateUI(e) {
      this.registration = e.detail;
      this.isRefresh = true;
    },
    update(){
     this.isRefresh = false;
     if (this.registration || this.registration.waiting) {
       this.registration.waiting.postMessage({type:'SKIP_WAITING'});
     }
    },
    setupHubConnectionWatcher() {
      setInterval(() => {
        console.log(`HubConnection: ${this.isConnected}`);
        if (!this.isConnected) {
          console.log(`Reconnecting...`);
          this.initialize();
        }
      }, 5000);
    }
  },
  computed: {
    ...mapGetters("MessageHubModule", ['isConnected']),
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  async mounted() {
    console.log("started");
    try {
      this.isLoading = true;

      const refreshSuccess = await authService.refreshSession();

      if (!refreshSuccess) {
        console.log('Refresh failed');
        authService.signOut();      
        return;
      } 
      this.initialize();

      document.addEventListener("visibilitychange", () => {
          if (!document.hidden && !this.isConnected) {
            this.reconnect();
          }
      });

      this.setupHubConnectionWatcher();
      
    } finally {
      this.isLoading = false;
    }
  },
  created() {
    document.addEventListener(
      'serviceWorkerUpdateEvent', this.appUpdateUI, { once: true }
    );
    navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
          if (this.refreshing) {
            return;
          }
          this.refreshing = true;
          window.location.reload();
        }
    );
    window.addEventListener('offline', () => {
      this.setConnected(false);
    });
    window.addEventListener('online', () => {
      this.setConnected(true);
    });
  }
}
</script>

<style>
  /* #app {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 500px;
    width: 100%;
  } */
  body.dialog-is-open {
    overflow: hidden !important;
    position: fixed;
  }
  .v-data-table-header th {
    white-space: nowrap;
  }

  .title-bar {
    background-color: #fff;
    text-align: center;
    font-size: 32px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 90%;
    margin: 18px auto;
    border-radius: 40px;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 40%);
  }

   .full-width {
     width: 100% !important;
   }
</style>
