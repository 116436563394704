<template>
    <v-snackbar 
        v-model="snackbar.showing" 
        :timeout="snackbar.timeout" 
        :color="snackbar.color" 
        :top="snackbar.top"
    >
        {{snackbar.text}}
    </v-snackbar>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "Snackbar",  
  computed: {
    ...mapState('CoreModule', ['snackbar'])
  }  
};
</script>

<style scoped></style>
