import budgetService from "@/services/budget.service.js";

const getDefaultState = () => {
    return {
        transactionTypes: [],
        budgetCategories: [],
        transactionStatuses: [],
        spendLimits: [],
        entryFormTransaction: '',
    }
}

export const BudgetModule = {
    namespaced: true,
    state: getDefaultState(),

    getters: {
        transactionTypes: state => state.transactionTypes,
        budgetCategories: state => state.budgetCategories,
        transactionStatuses: state => state.transactionStatuses,
        spendLimits: state => state.spendLimits,
        entryFormTransaction: state => state.entryFormTransaction,
    },

    mutations: {
        RESET_STATE(state) {            
            Object.assign(state, getDefaultState());
        },
        SET_TRANSACTION_TYPES(state, types) {
            state.transactionTypes = [...types];
        },
        SET_TRANSACTION_STATUSES(state, statuses) {
            state.transactionStatuses = [...statuses];
        },
        SET_BUDGET_CATEGORIES(state, categories) {
            state.budgetCategories = [...categories];
        },
        SET_SPEND_LIMITS(state, limits) {
            state.spendLimits = [...limits];
        },
        SET_ENTRY_FORM_TRANSACTION(state, transaction) {
            state.entryFormTransaction = {...transaction};
        },
        CLOSE_TRANSACTION_ENTRY_FORM(state) {
            state.entryFormTransaction = '';
        }
    },

    actions: { 
        resetState({commit}) {
            commit('RESET_STATE');             
        },
        async setTransactionTypes({commit}) {
            const transactionTypesResult = await budgetService.getTransactionTypes();
            commit('SET_TRANSACTION_TYPES', transactionTypesResult.data);
        },
        async setTransactionStatuses({commit}) {
            const transactionStatusesResult = await budgetService.getTransactionStatuses();
            commit('SET_TRANSACTION_STATUSES', transactionStatusesResult.data);
        },
        async setBudgetCategories({commit}) {
            const categoriesResult = await budgetService.getBudgetCategories();
            const sorted = categoriesResult.data.sort((a, b) => {
                if (a.sortOrder < b.sortOrder) return -1;
                else if (a.sortOrder > b.sortOrder) return 1;
                else return 0;
            });
            commit('SET_BUDGET_CATEGORIES', sorted);
        },
        setSpendLimits({commit}, limits) {
            commit('SET_SPEND_LIMITS', limits);
        },

        setEntryFormTransaction({commit}, transaction) {
            commit('SET_ENTRY_FORM_TRANSACTION', transaction);
        },
        closeTransactionForm({commit}) {
            commit('CLOSE_TRANSACTION_ENTRY_FORM')
        }
    }
}
