const getDefaultState = () => {
    return {
        currentUser: {}
    }
}

export const AuthModule = {
    namespaced: true,
    state: getDefaultState(),

    getters: {
        currentUser: state => state.currentUser,
        isAuthenticated: state => !!state.currentUser.email,
        hasRole: (state) => (roleName) => {
            const hasRole = state.currentUser && state.currentUser.roles
                ? state.currentUser.roles.filter(r => r.name === roleName).length > 0 
                : false;

            return hasRole;
        }
    },

    mutations: {
        RESET_STATE(state) {            
            Object.assign(state, getDefaultState());
        },
        SET_CURRENT_USER(state, user) {
            state.currentUser = {...user};
        },
    },

    actions: { 
        resetState({commit}) {
            commit('RESET_STATE');             
        },
        setCurrentUser({commit}, user) {
            commit('SET_CURRENT_USER', user);
        },
    }
}
