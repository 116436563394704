<template>
  <v-menu v-if="isAuthenticated" :offset-y="true">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon text v-bind="attrs" v-on="on">                      
        <v-icon>mdi-cog</v-icon>
      </v-btn>     
    </template>
      <v-list>
        <v-list-item dense>
          <v-list-item-avatar color="darkGray" class="avatar justify-self-center">
              <v-icon>mdi-account</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        
        <v-list-item @click="signOut()" dense>
          <v-list-item-avatar color="darkGray" class="avatar justify-self-center">
            <v-icon left>mdi-logout-variant</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Sign out</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
import authService from '@/services/auth.service.js';
import {mapGetters} from 'vuex';

export default {
  name: "user-menu",
  computed: {
    ...mapGetters("AuthModule", { isAuthenticated: "isAuthenticated" }),
  },
  methods: {
    signOut() {
      authService.signOut();
    }
  },  
};
</script>
