<template>
    <v-tooltip v-if="isAuthenticated && !isConnected" bottom color="error">
      <template v-slot:activator="{ on, attrs }">
        <v-chip 
          class="ma-2" 
          color="error" 
          text-color="white"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi mdi-power-plug-off-outline</v-icon>
        </v-chip>
      </template>
      <span>Lost socket connection</span>
    </v-tooltip>          
  </template>
  
  
  <script>
  import { mapGetters } from "vuex";  

  export default {
    name: 'WebSocketStatusIndicator',
    data: () => ({
      isLoading: false,
    }),
  
    computed: {
        ...mapGetters("MessageHubModule", ["isConnected"]),
        ...mapGetters("AuthModule", { isAuthenticated: "isAuthenticated" }),        
  },
    methods: {      
    },  
    watch: {      
    }
    
  };
  </script>
  
  <style scoped>
      .deleted {
        text-decoration: line-through;
        color: red;
      }
  </style>
  