import axios from '@/plugins/axios.js';
import router from '@/router/index.js';
import store from '@/store/index.js';
import toasterHandler from "@/utils/toaster.handler.js";

export default {
    JWT_TOKEN: 'jwt_token',
    REFRESH_TOKEN: 'refresh_token',
    
    checkAuth() {
        console.log('checking auth status');
        return axios.get('/auth/checkAuth');
    },
    async login(signInModel) {
        try {
            const response = await axios.post(`/auth/login`, signInModel);

            if (response) {
                this.setCurrentUser(response.data);
                this.storeAccessToken(response.data.jwtToken);
                this.storeRefreshToken(response.data.refreshToken);
                this.startRefreshTokenTimer();      
                store.dispatch('MessageHubModule/initialize');   
            } else {
                toasterHandler.showToast('error', 'mdi-lock-off-outline', null, "Invalid credentials. Please check your login info and try again", null, null, null);
            }
        } catch (err) {
            toasterHandler.showToast('error', 'mdi-lock-off-outline', null, "An error occurred while logging in", null, null, null);
        }
            
    },
    refreshSession() {
        console.log('RefreshSession function called...');
        const accessToken = localStorage.getItem(this.JWT_TOKEN);
        const refreshToken = localStorage.getItem(this.REFRESH_TOKEN);

        return new Promise((resolve) => {
            if (!refreshToken) {
                resolve(false);
                return;
            }
            const url = `/Auth/Refresh`;

            const params = {
                accessToken: accessToken,
                refreshToken: refreshToken
            };

            axios.post(url, params)
                .then(refreshResponse => {
                    if (!refreshResponse) {
                        console.log('Refresh returned no result...');
                        resolve(false);
                        return;
                    }
                
                    console.log('Refresh returned new access/refresh tokens...');
                    this.setCurrentUser(refreshResponse.data);
                    this.storeAccessToken(refreshResponse.data.jwtToken);
                    this.storeRefreshToken(refreshResponse.data.refreshToken);
                    this.startRefreshTokenTimer();
                    resolve(true);
                }).catch(() => {
                    resolve(false);
                })
        });
    },
    async signOut() {
        console.log('Signing out...');

        Object.keys(localStorage)
            .filter(x => !x.startsWith('persist'))
            .forEach(x => localStorage.removeItem(x))

        store.dispatch('MessageHubModule/disconnect');
        store.dispatch('resetState');
        this.stopRefreshTokenTimer();

        axios.post(`/Auth/Logout`);
        router.replace({ name: "Login" });
    },

    getCurrentUserFromLocalStorage() {
        let lsUser = localStorage.getItem('currentUser');     
        return lsUser ? JSON.parse(lsUser) : null;
    },
    getAccessToken() {
        return localStorage.getItem(this.JWT_TOKEN);
    },
    getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN);
    },    
    hasRole(roleName) {
        if (!this.currentUser || !this.currentUser.roles || this.currentUser.roles.length === 0) {
            return false;
        }
        return !!this.currentUser.roles.find(r => r.name === roleName);
    },

    isAuthorized(allowedRoles) {
        if (allowedRoles == null || allowedRoles.length === 0) {
            return true;
        }

        for (let i = 0; i < allowedRoles.length; i++) {
            if (this.hasRole(allowedRoles[i])) {
                return true;
            }
        }

        return false;
    },

    storeAccessToken(token) {        
        localStorage.setItem(this.JWT_TOKEN, token);
    },
    storeRefreshToken(refreshToken) {
        localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
    },
    setCurrentUser(user) {
        if (!user) {
            user = this.getCurrentUserFromLocalStorage();
        } else {            
            localStorage.setItem('currentUser', JSON.stringify(user));
        }
        store.dispatch('AuthModule/setCurrentUser', user);
    },
    removeTokens() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem(this.JWT_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
    },
    isAccessTokenExpired() {
        const jwtToken = JSON.parse(atob(this.getAccessToken().split('.')[1]));
        const secondsRemaining = (new Date(jwtToken.exp * 1000).getTime() - Date.now()) / 1000;

        return secondsRemaining < 30;
    },

    // automatically refresh the auth session
    refreshTokenTimeout: null,

    startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.getAccessToken().split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        console.log(`Starting Refresh Timer for ${new Date(jwtToken.exp * 1000)}`);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshSession(), timeout);
    },

    stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

}