import axios from '@/plugins/axios.js';
import store from '@/store/index.js';
import geolocation from '@/utils/geolocation.js';
import moment from "moment";
import {format} from 'date-fns';


export default {
    getBudgetCategories() {
        console.log("Retrieving BudgetCategories");
        const url = '/BudgetCategories';
        return axios.get(url);
    },
    getTransactionTypes() {
        console.log("Retrieving TransactionTypes");
        const url = '/TransactionTypes';
        return axios.get(url);
    },
    getTransactionStatuses() {
        console.log("Retrieving TransactionStatuses");
        const url = '/TransactionStatuses';
        return axios.get(url);
    },
    searchTransactions(searchText, startDate, endDate, planned, sent, pending, cleared) {
        console.log("Retrieving Transactions by search");
        startDate = startDate ? startDate : format(new Date(0,0,0), 'yyyy-MM-dd');
        endDate = endDate ? endDate : format(new Date(2999,12,31), 'yyyy-MM-dd');
        const url = `/BudgetTransactions/Search?searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&planned=${planned}&sent=${sent}&pending=${pending}&cleared=${cleared}`
        return axios.get(url);
    },
    getTransactionsByDateRange(startDate, endDate) {
        console.log("Retrieving Transactions by date range");
        const url = `/BudgetTransactions/${startDate}/${endDate}`;
        return axios.get(url);
    },

    addTransaction(transaction) {
        console.log("Adding transaction");
        const url = `/BudgetTransactions`;
        const currentUser = store.getters['AuthModule/currentUser'];

        const budgetTransaction = {
            id: 0,
            transactionDate: transaction.transactionDate,
            payPeriodDate: transaction.payPeriodDate,
            budgetCategoryId: transaction.budgetCategoryId,
            transactionTypeId: transaction.transactionTypeId,
            transactionStatusId: transaction.transactionStatusId,
            description: transaction.description,
            amount: transaction.amount,
            memo: transaction.memo,
            tipAmount: transaction.tipAmount,
            confirmationNumber: transaction.confirmationNumber,
            checkNumber: transaction.checkNumber,
            createdDateTime: new Date(),
            createdUtcOffset: new Date().getTimezoneOffset(),
            createdTimeZone: new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2],
            createdBy: currentUser.email,
            createdLocationLatitude: transaction.createdLocationLatitude,
            createdLocationLongitude: transaction.createdLocationLongitude,

            transactionType: null,
            transactionStatus: null,
            budgetCategory: null
        };

        return axios.post(url, budgetTransaction);
    },
    updateTransaction(transaction) {
        const url = `/BudgetTransactions`;
        const currentUser = store.getters['AuthModule/currentUser'];

        let budgetTransaction = {
            id: transaction.id,
            transactionDate: transaction.transactionDate,
            payPeriodDate: transaction.payPeriodDate,
            budgetCategoryId: transaction.budgetCategoryId,
            transactionTypeId: transaction.transactionTypeId,
            transactionStatusId: transaction.transactionStatusId,
            amount: transaction.amount,
            description: transaction.description,
            memo: transaction.memo,
            tipAmount: transaction.tipAmount,
            confirmationNumber: transaction.confirmationNumber,
            checkNumber: transaction.checkNumber,
            createdDateTime: transaction.createdDateTime ? transaction.createdDateTime : new Date(),
            createdUtcOffset: transaction.createdUtcOffset ? transaction.createdUtcOffset : new Date().getTimezoneOffset(),
            createdTimeZone: transaction.createdTimeZone ? transaction.createdTimeZone : new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2],
            createdBy: transaction.createdBy && transaction.createdBy.trim().length > 0 ? transaction.createdBy : currentUser.email,
            createdLocationLatitude: transaction.createdLocationLatitude ? transaction.createdLocationLatitude : null,
            createdLocationLongitude: transaction.createdLocationLongitude ? transaction.createdLocationLongitude : null,

            transactionType: null,
            transactionStatus: null,
            budgetCategory: null
        };

        return axios.put(url, budgetTransaction);
    },
    updateTransactionLocation(transactionId, latitude, longitude) {
        const url = `BudgetTransactions/${transactionId}/UpdateLocation?latitude=${latitude}&longitude=${longitude}`;
        return axios.patch(url);
    },
    deleteTransaction(transaction) {
        const url = `/BudgetTransactions/${transaction.id}`
        return axios.delete(url);
    },    
    getStartingBalance(startDate) {
        const url = `/Budget/GetStartingBalance?startDate=${startDate}`;    
        return axios.get(url);
    },    
    getBankAccountBalance() {
        const url = `/Budget/GetAccountBalance`;    
        return axios.get(url);
    },
    async addRecurringTransactions(date) {
        const url = `/RecurringTransactions/Generate`;
        const now = new Date();    
        const coords = await geolocation.getLocation();
        const currentUser = store.getters['AuthModule/currentUser'];
    
        const vm = {
          date: date,
          createdDateTime: now,
          createdUtcOffset: now.getTimezoneOffset(),
          createdTimeZone: now.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2],
          createdBy: currentUser.email,
          createdLocationLatitude: coords ? coords.latitude : null,
          createdLocationLongitude: coords ? coords.longitude : null
        };
        
        return axios.post(url, vm)
    },

    getRecurringTransactions() {
        const url = `/RecurringTransactions`;
        return axios.get(url);
    },
    getAddRecurringTransactionsVisibility(startDate) {
        const url = `/RecurringTransactions/getAddRecurringTransactionsVisibility?periodStartDate=${startDate}`;
        return axios.get(url);
    },
    getSpendingLimits(date) {
        if (!date) {
            date = moment().toDate().toISOString();
        }
        const url = `/BudgetCategorySpendLimits?date=${date}`;
    
        return axios.get(url);
    },
    getCategorySpendingLimit(categoryId, date) {
        if (!date) {
          date = moment().toDate();
        }
        const url = `/BudgetCategorySpendLimits/Category/${categoryId}?date=${date.toISOString()}`;
    
        return axios.get(url);
    },
    setSpendingLimit(categorySpendLimit) {
        const url = `/BudgetCategorySpendLimits`;
    
        if (!categorySpendLimit.amount || categorySpendLimit.amount < 0) {
          categorySpendLimit.amount = null;
        }
        
        return axios.post(url, categorySpendLimit);
    }
}
