import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import authService from '@/services/auth.service.js';
import toasterHandler from "@/utils/toaster.handler.js";
import { format } from 'date-fns';
import * as signalR from '@microsoft/signalr'
import Vue from "vue";

const getDefaultState = () => {
    return {        
        hubConnection: null,
        messages: [],
        accessToken: null
    }
}

export const MessageHubModule = {
    namespaced: true,
    state: getDefaultState(),    
    getters: {
        isConnected: state => {            
            return state.hubConnection && state.hubConnection.state === signalR.HubConnectionState.Connected;
        },
        isSocketConnected: state => {
            return state.hubConnection.receivedHandshakeResponse;
        }
    },
    mutations: {
        DISCONNECT(state) {
            if (state.hubConnection) {
                state.hubConnection.stop();
            }
        }, 
        RECONNECT(state) {
            state.hubConnection.start();
        },
        RESET_STATE(state) {            
            Object.assign(state, getDefaultState());
        },
        INITIALIZE(state) {
            state.accessToken = authService.getAccessToken();
            state.hubConnection = new HubConnectionBuilder()
                .withUrl(`${process.env.VUE_APP_WEBAPI_URL}/messageHub`, { accessTokenFactory: () => `${state.accessToken}`, withCredentials: false })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Information)
                .build();
 
            state.hubConnection.start()
                .then(() => {
                    state.hubConnection.invoke("JoinAuthenticatedUsers");
                    console.log("Connected to socket...");
                });
            
            state.hubConnection.onreconnected(() => {        
                console.log("Reconnected to socket...");
            });

            state.hubConnection.onclose(() => {
                console.log("Lost socket connection...");
            });

            state.hubConnection.on("UserConnected", (user) => {
                toasterHandler.showToast(
                    'info', 
                    'mdi-login',
                    null, 
                    `${user} connected`, 
                    null, 
                    null,
                    null);
            });

            state.hubConnection.on("UserDisconnected", (user) => {
                toasterHandler.showToast(
                    'info', 
                    'mdi-info',
                    null, 
                    `${user} disconnected`, 
                    null, 
                    null,
                    null);
            });

            state.hubConnection.on("BroadcastReceived", (user, message) => {
                toasterHandler.showToast(
                    'info', 
                    'mdi-info-slab-circle-outline',
                    `Message from ${user}`, 
                    `${message}`, 
                    null, 
                    null,
                    null);
            });

            // =========== Push Notifications ===========
            state.hubConnection.on("PushNotificationReceived", (message) => {
                console.log({push: message});
                toasterHandler.showToast(
                    'info', 
                    'mdi-bell-outline',
                    `Notification`, 
                    `Message: ${message}`, 
                    null, 
                    null,
                    null);   
            });

            // ========== Budget Transactions ============
            state.hubConnection.on("BudgetTransactionAdded", (user, budgetTransaction) => {
                toasterHandler.showToast(
                    'info', 
                    'mdi-plus',
                    `Transaction added`, 
                    `${user}\n
                        ${budgetTransaction.description}\n
                        ${format(new Date(budgetTransaction.transactionDate), 'M/dd/yyyy')}\n
                        ${Vue.filter('currency')(budgetTransaction.amount)}`, 
                    null, 
                    null,
                    null);                    
            }); 
            
            state.hubConnection.on("BudgetTransactionEdited", (user, budgetTransaction) => {
                toasterHandler.showToast(
                    'info', 
                    'mdi-pencil',
                    `Transaction edited`, 
                    `${user}\n
                        ${budgetTransaction.description}\n
                        ${format(new Date(budgetTransaction.transactionDate), 'M/dd/yyyy')}\n
                        ${Vue.filter('currency')(budgetTransaction.amount)}`, 
                    null, 
                    null,
                    null);                
            }); 

            state.hubConnection.on("BudgetTransactionDeleted", (user, budgetTransaction) => {
                toasterHandler.showToast(
                    'info', 
                    'mdi-delete-outline',
                    `Transaction deleted`, 
                    `${user}\n
                        ${budgetTransaction.description}\n
                        ${format(new Date(budgetTransaction.transactionDate), 'M/dd/yyyy')}\n
                        ${Vue.filter('currency')(budgetTransaction.amount)}`, 
                    null, 
                    null,
                    null);                    
            }); 

            state.hubConnection.on("RecurringBudgetTransactionsAdded", (user, date) => {   
                toasterHandler.showToast(
                    'info', 
                    'mdi-plus',
                    `Recurring budget transactions added`, 
                    `${user}\nPeriod Starting: ${format(Date.parse(date), 'MM/dd/yyyy')}`, 
                    null, 
                    null,
                    null);
            }); 

            // =========== Fuel Entries ===========
            state.hubConnection.on("FuelEntryAdded", (user, fuelEntry) => {
                toasterHandler.showToast(
                    'info', 
                    'mdi-plus',
                    `Fuel entry added`, 
                    `${user}\n
                        Vehicle: ${fuelEntry.vehicle.name}\n
                        Location: ${fuelEntry.location}\n
                        Date: ${fuelEntry.entryDate}\n
                        Amount: ${ Vue.filter('currency')(fuelEntry.totalCost)}`, 
                    null, 
                    null,
                    null);
            }); 

            state.hubConnection.on("FuelEntryEdited", (user, fuelEntry) => {
                toasterHandler.showToast(
                    'info', 
                    'mdi-pencil',
                    `Fuel entry edited`, 
                    `${user}\n
                    Vehicle: ${fuelEntry.vehicle.name}\n
                    Location: ${fuelEntry.location}\n
                    Date: ${fuelEntry.entryDate}\n
                    Amount: ${ Vue.filter('currency')(fuelEntry.totalCost)}`, 
                    null, 
                    null,
                    null);                
            }); 

            state.hubConnection.on("FuelEntryDeleted", (user, fuelEntry) => {
                toasterHandler.showToast(
                    'info', 
                    'mdi-delete-outline',
                    `Fuel Entry Deleted`, 
                    `${user}\n
                    Vehicle: ${fuelEntry.vehicle.name}\n
                    Location: ${fuelEntry.location}\n
                    Date: ${fuelEntry.entryDate}\n
                    Amount: ${ Vue.filter('currency')(fuelEntry.totalCost)}`, 
                    null, 
                    null,
                    null);   
            });
        },
        SEND_BROADCAST(state, message) {
            state.messages = [...state.messages, message];            
            state.hubConnection.invoke("SendBroadcast", message);
        },
        // Budget
        SEND_BUDGET_TRANSACTION_ADDED(state, transaction) {
            state.hubConnection.invoke("SendBudgetTransactionAdded", transaction);
        },
        SEND_BUDGET_TRANSACTION_EDITED(state, transaction) {
            state.hubConnection.invoke("SendBudgetTransactionEdited", transaction);
        },
        SEND_BUDGET_TRANSACTION_DELETED(state, transaction) {
            state.hubConnection.invoke("SendBudgetTransactionDeleted", transaction);
        },
        SEND_RECURRING_BUDGET_TRANSACTIONS_ADDED(state, date) {
            state.hubConnection.invoke("SendRecurringBudgetTransactionAdded", date);
        },

        // Fuel Entry
        SEND_FUEL_ENTRY_ADDED(state, fuelEntry) {
            state.hubConnection.invoke("SendFuelEntryAdded", fuelEntry);
        },
        SEND_FUEL_ENTRY_EDITED(state, fuelEntry) {
            state.hubConnection.invoke("SendFuelEntryEdited", fuelEntry);
        },
        SEND_FUEL_ENTRY_DELETED(state, fuelEntry) {
            state.hubConnection.invoke("SendFuelEntryDeleted", fuelEntry);
        },

    },

    actions: { 
        resetState({commit}) {
            commit('RESET_STATE');             
        },  
        softDisconnect({commit}) {            
            commit('DISCONNECT');
        },      
        disconnect({commit}) {            
            commit('DISCONNECT');
            commit('RESET_STATE');
        },
        reconnect({commit}) {
            commit('RECONNECT');
        },
        initialize({commit}) {
            commit('DISCONNECT');
            commit('RESET_STATE');
            commit('INITIALIZE');
        },

        sendBroadcast({commit}, message) {
            commit('SEND_BROADCAST', message);
        },
        
        // Budget
        sendBudgetTransactionAdded({commit}, transaction) {
            commit("SEND_BUDGET_TRANSACTION_ADDED", transaction);
        },
        sendBudgetTransactionEdited({commit}, transaction) {
            commit("SEND_BUDGET_TRANSACTION_EDITED", transaction);
        },
        sendBudgetTransactionDeleted({commit}, transaction) {
            commit("SEND_BUDGET_TRANSACTION_DELETED", transaction);
        },
        sendRecurringBudgetTransactionsAdded({commit}, date) {
            console.log({date});
            commit("SEND_RECURRING_BUDGET_TRANSACTIONS_ADDED", date);
        },

        // Fuel Entry
        sendFuelEntryAdded({commit}, fuelEntry) {
            commit("SEND_FUEL_ENTRY_ADDED", fuelEntry);
        },
        sendFuelEntryEdited({commit}, fuelEntry) {            
            commit("SEND_FUEL_ENTRY_EDITED", fuelEntry);
        },
        sendFuelEntryDeleted({commit}, fuelEntry) {
            commit("SEND_FUEL_ENTRY_DELETED", fuelEntry);
        }
    }
}
