export default {
    getLocation() {
        let options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };
    
        return new Promise((resolve) => {
            navigator.geolocation.getCurrentPosition(result => {
                // On Geolocation Success...
                if (result && result.coords) {
                    resolve(result.coords);
                }
            }, () => {
                resolve(null);
            }, options);
        })
    }    
}