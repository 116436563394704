
const getDefaultState = () => {
    return {
        menuIsOpen: false,
        snackbar: {},
    }
}

export const CoreModule = {
    namespaced: true,
    state: getDefaultState(),

    getters: {
        menuIsOpen: state => state.menuIsOpen,
    },

    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        OPEN_MENU(state) {
            state.menuIsOpen = true;
        },
        CLOSE_MENU(state) {
            state.menuIsOpen = false;
        },
        TOGGLE_MENU(state) {
            state.menuIsOpen = !state.menuIsOpen;
        },
        SET_SNACKBAR(state, snackbar) {
            state.snackbar = snackbar;
        },
    },

    actions: { 
        resetState({commit}) {
            commit('RESET_STATE');             
        },
        openMenu({commit}) {
            commit("OPEN_MENU");
        },
        closeMenu({commit}) {
            commit("CLOSE_MENU");
        },
        toggleMenu({commit}) {
            commit("TOGGLE_MENU");
        },
        setSnackbar({ commit }, snackbar) {
            commit("SET_SNACKBAR", snackbar);
        },
    }
}
